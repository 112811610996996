import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'

import Layout from '../layouts/default/layout';

const ResetPasswordPage = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  useEffect(()=>{
    setData(dataJSON)    
    resetPasswordActive()
  }, [])

  const resetPasswordActive = ()=>{
    let interval = setInterval(()=>{
        if(document.querySelector('ae-login-modal')){
            clearInterval(interval)
            let resetPasswordComponent = document.createElement('ae-change-forgot-password')
            document.querySelector('.content-reset-password').appendChild(resetPasswordComponent)

            document.querySelector('ae-change-forgot-password').show = true

            document.querySelector('ae-change-forgot-password').addEventListener('success', ()=>{
              window.location.href = '/?login=true'
            })


        }
    }, 1000)
     
  }

  
  return (
    <>
      <title>{dataJSON.customer  ? dataJSON.customer.name + ' - Recuperar senha' : ''}</title>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="">
          <div className="row">
            <div className="col-md-12 content-reset-password mt-24">

            </div>
          </div>


        </div>
      </Layout>

    </>
  )
}

export default ResetPasswordPage
